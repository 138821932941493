import React, { useContext } from "react";
import {
    AccordionItem,
    Accordion,
    AccordionButton,
    AccordionPanel,
    Stack,
    Box,
    AccordionIcon,
    Icon,
    Image,
    Flex
} from "@chakra-ui/react";
import { useLocation, Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineSetting } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { RiDashboardLine } from 'react-icons/ri';
import { IoMdCloudDownload } from 'react-icons/io';
import { MdVerifiedUser } from 'react-icons/md';
import { BiUserCheck } from 'react-icons/bi';
import { Context } from '../../context/Context';
import Can from '../../utils/rbac/Can';


const DesktopMenu = () => {

    const { user } = useContext(Context)

    const { role, tenant } = user

    const location = useLocation()

    const activePath = location.pathname.split("/")[1]
    const secondaryActivePath = location.pathname.split("/")[2]


    console.log(tenant?.logo);

    return (
        <Stack
            borderRightWidth="1px"
            width={[
                "100%", // base
                "50%", // 480px upwards
                "25%", // 768px upwards
                "25%", // 992px upwards
            ]}
        >
            <Stack
                position="sticky"
                top={0}

            >

                <Box pt={5} pb={4}>
                    <Flex justify="space-between">
                        <Image src={tenant?.logo ?? "https://res.cloudinary.com/ddjyel5tz/image/upload/v1608236779/logo_yhekkq.jpg"} alt="Logo" w={150} pl={2} />
                    </Flex>
                </Box>

                <Accordion allowToggle borderColor="transparent">
                    <Can
                        role={role}
                        perform="dashboard:visit"
                        yes={() => (
                            <AccordionItem
                                py={2}>
                                <AccordionButton
                                    bg={activePath === "dashboard" && "brand.lightGreen"}
                                    color={activePath === "dashboard" && "brand.green"}
                                    borderLeftWidth={"4px"}
                                    borderLeftColor={activePath === "dashboard" ? "brand.green" : 'transparent'}

                                >
                                    <Icon as={RiDashboardLine} mr={4} w={5} h={5} />
                                    <Box flex="1" textAlign="left">
                                        {/* <Icon as={AiOutlineEdit} mr={4} /> */}
                                        Dashboard
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={4}>
                                    <Stack spacing={4} ml={10}>
                                        <Link to={`/dashboard`}>
                                            <Box flex="1"
                                                color={(activePath === "dashboard" && !secondaryActivePath) ? "brand.green" : 'text'}
                                                textAlign="left"
                                                _hover={{ color: 'brand.green' }}>
                                                Dashboard
                                            </Box>
                                        </Link>
                                        <Link to={`/dashboard/mapping-insights`}>
                                            <Box flex="1"
                                                color={(secondaryActivePath === "mapping-insights") ? "brand.green" : 'text'}
                                                textAlign="left"
                                                _hover={{ color: 'brand.green' }}>
                                                Mapping Insights
                                            </Box>
                                        </Link>

                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="user-management:visit"
                        yes={() => (
                            <AccordionItem py={2}

                            >
                                <AccordionButton
                                    bg={activePath === "users" && "brand.lightGreen"}
                                    color={activePath === "users" && "brand.green"}
                                    borderLeftWidth={"4px"}
                                    borderLeftColor={activePath === "users" ? "brand.green" : 'transparent'}

                                >
                                    <Icon as={AiOutlineUser} mr={4} w={5} h={5} />
                                    <Box

                                        flex="1" textAlign="left" fontWeight="lg">
                                        User Management
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={4}>
                                    <Stack spacing={4} ml={10}>
                                        <Link to={`/users`}>
                                            <Box
                                                color={(activePath === "users" && !secondaryActivePath) ? "brand.green" : 'text'}
                                                _hover={{ color: 'brand.green' }}
                                                flex="1" textAlign="left"
                                            >
                                                Users
                                            </Box>
                                        </Link>
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="verification:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/verification`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "verification" && "brand.lightGreen"}
                                        color={activePath === "verification" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "verification" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={MdVerifiedUser} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Verification List
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="tenants:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/tenants`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "tenants" && "brand.lightGreen"}
                                        color={activePath === "tenants" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "tenants" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={BiUserCheck} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Tenants
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="downloads:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/downloads`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "downloads" && "brand.lightGreen"}
                                        color={activePath === "downloads" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "downloads" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={IoMdCloudDownload} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Downloads
                                        </Box>
                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="settings:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <AccordionButton
                                    bg={activePath === "settings" && "brand.lightGreen"}
                                    color={activePath === "settings" && "brand.green"}
                                    borderLeftWidth={"4px"}
                                    borderLeftColor={activePath === "settings" ? "brand.green" : 'transparent'}

                                >
                                    <Icon as={AiOutlineSetting} mr={4} w={5} h={5} />
                                    <Box

                                        flex="1" textAlign="left" fontWeight="lg">
                                        Settings
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel py={4}>
                                    <Stack spacing={4} ml={10}>

                                        <Can
                                            role={role}
                                            perform="settings:developer"
                                            yes={() => (
                                                <Link to={`/settings/developer`}>
                                                    <Box
                                                        _hover={{ color: 'brand.green' }}
                                                        flex="1"
                                                        textAlign="left"
                                                        color={secondaryActivePath === "developer" ? "brand.primary" : 'text'}
                                                    >
                                                        Developer
                                                    </Box>
                                                </Link>

                                            )}
                                            no={() => null}
                                        />

                                        <Can
                                            role={role}
                                            perform="settings:configuration"
                                            yes={() => (
                                                <Link to={`/settings/configuration`}>
                                                    <Box
                                                        _hover={{ color: 'brand.green' }}
                                                        flex="1"
                                                        textAlign="left"
                                                        color={secondaryActivePath === "configuration" ? "brand.primary" : 'text'}
                                                    >
                                                        Configuration
                                                    </Box>
                                                </Link>

                                            )}
                                            no={() => null}
                                        />

                                        <Can
                                            role={role}
                                            perform="countries:visit"
                                            yes={() => (
                                                <Link to={`/countries`}>
                                                    <Box
                                                        _hover={{ color: 'brand.green' }}
                                                        flex="1"
                                                        textAlign="left"
                                                        color={activePath === "countries" ? "brand.primary" : 'text'}
                                                    >
                                                        Countries
                                                    </Box>
                                                </Link>

                                            )}
                                            no={() => null}
                                        />
                                        <Can
                                            role={role}
                                            perform="currencies:visit"
                                            yes={() => (
                                                <Link to={`/currencies`}>
                                                    <Box
                                                        _hover={{ color: 'brand.green' }}
                                                        flex="1"
                                                        textAlign="left"
                                                        color={activePath === "currencies" ? "brand.primary" : 'text'}
                                                    >
                                                        Currencies
                                                    </Box>
                                                </Link>

                                            )}
                                            no={() => null}
                                        />
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />
                    <Can
                        role={role}
                        perform="report:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/report`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "report" && "brand.lightGreen"}
                                        color={activePath === "report" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "report" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={HiOutlineDocumentReport} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Report
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="quickVerification:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/quick-verification`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "quick-verification" && "brand.lightGreen"}
                                        color={activePath === "quick-verification" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "quick-verification" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={MdVerifiedUser} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Quick Verification
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="vegetation:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/dashboard`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "dashboard" && "brand.lightGreen"}
                                        color={activePath === "dashboard" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "dashboard" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={MdVerifiedUser} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Vegetation Check
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>

                        )}
                        no={() => null}
                    />

                    <Can
                        role={role}
                        perform="vegetation:visit"
                        yes={() => (
                            <AccordionItem py={2}>
                                <Link to={`/settlement-check`}>
                                    <AccordionButton
                                        variant="ghost"
                                        bg={activePath === "settlement-check" && "brand.lightGreen"}
                                        color={activePath === "settlement-check" && "brand.green"}
                                        borderLeftWidth={"4px"}
                                        borderLeftColor={activePath === "settlement-check" ? "brand.green" : 'transparent'}

                                    >
                                        <Icon as={MdVerifiedUser} mr={4} w={5} h={5} />

                                        <Box

                                            flex="1" textAlign="left" fontWeight="lg">
                                            Settlement Check
                                        </Box>

                                    </AccordionButton>
                                </Link>
                            </AccordionItem>
                        )}
                        no={() => null}
                    />
                </Accordion>
            </Stack>

        </Stack>
    );
};

export default DesktopMenu;